import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    meta: {
      title: '首页',
      renderMenu: false,
      icon: 'CreditCardOutlined',
    },
  },
  {
    path: '/front',
    name: '前端',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [
      {
        path: '/login',
        name: '登录',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
        },
        component: () => import('@/pages/login'),
      },
      {
        path: '/home',
        name: '首页',
        meta: {
          view: 'blank',
        },
        component: () => import('@/pages/home'),
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
  // {
  //   path: '/customer',
  //   name: '客户支持',
  //   component: () => import('@/components/layout/BlankView.vue'),
  //   meta: {
  //     description: '无论何时何地，各路前端大神就在您身边，帮助您提升开发效率。',
  //     extraList: [
  //       {
  //         title: '合作伙伴反馈意见',
  //         description: '伙伴专属通道，期待您的反馈',
  //         icon: 'icon-tengxunweifuwupingtai-tengxunyun-',
  //       },
  //       {
  //         title: '推广大使邀新奖励',
  //         description: '邀请新用户上云，最高可得8万月佣金',
  //         icon: 'icon-tengxunyunwendangzhongxin-tengxunyun-2',
  //       },
  //     ],
  //   },
  //   children: [
  //     {
  //       path: 'tool&doc',
  //       name: '文档与工具',
  //       meta: {
  //         linkList: [
  //           { title: '文档与工具', href: 'https://www.baidu.com' },
  //           { title: '文档中心', href: 'https://www.baidu.com' },
  //           { title: '入门中心', href: 'https://www.baidu.com' },
  //           { title: 'API中心', href: 'https://www.baidu.com' },
  //           { title: 'SDK中心', href: 'https://www.baidu.com' },
  //           { title: '命令行工具', href: 'https://www.baidu.com' },
  //           { title: '文档活动', href: 'https://www.baidu.com' },
  //         ],
  //       },
  //       component: () => import('@/components/layout/BlankView.vue'),
  //     },
  //     {
  //       path: 'service',
  //       name: '服务支持',
  //       meta: {
  //         linkList: [
  //           { title: '在线支持', href: 'https://www.baidu.com' },
  //           { title: '建议反馈', href: 'https://www.baidu.com' },
  //           { title: '自助服务中心', href: 'https://www.baidu.com' },
  //           { title: '常见问题中心', href: 'https://www.baidu.com' },
  //           { title: '问答社区', href: 'https://www.baidu.com' },
  //           { title: '服务公告', href: 'https://www.baidu.com' },
  //           { title: '联系我们', href: 'https://www.baidu.com' },
  //         ],
  //       },
  //       component: () => import('@/components/layout/BlankView.vue'),
  //     },
  //     {
  //       path: 'protection',
  //       name: '服务保障',
  //       meta: {
  //         linkList: [
  //           { title: '技术服务专家', href: 'https://www.baidu.com', badge: 'HOT' },
  //           { title: '服务计划', href: 'https://www.baidu.com' },
  //           { title: '服务保障', href: 'https://www.baidu.com' },
  //           { title: '举报平台', href: 'https://www.baidu.com' },
  //           { title: '腾讯云健康看板', href: 'https://www.baidu.com' },
  //         ],
  //       },
  //       component: () => import('@/components/layout/BlankView.vue'),
  //     },
  //     {
  //       path: 'exclusive',
  //       name: '专属服务',
  //       meta: {
  //         linkList: [
  //           { title: '迁移服务', href: 'https://www.baidu.com' },
  //           { title: '护航保障', href: 'https://www.baidu.com' },
  //           { title: '高可用服务', href: 'https://www.baidu.com' },
  //         ],
  //       },
  //       component: () => import('@/components/layout/BlankView.vue'),
  //     },
  //   ],
  // },
];

export default routes;
