<script lang="ts" setup>
  import { useRoute } from 'vue-router';
  import { MenuConfig } from 'stepin/es/router-menu';
  import { PropType, computed, ref } from 'vue';
  import IconFont from '@/plugins/iconfont/IconFont.vue';

  const props = defineProps({
    menuList: Array as PropType<MenuConfig[]>,
  });
  const route = useRoute();
  const matched = computed(() => route.matched);

  const popupVisible = ref(false);

  const subMenuList = ref<MenuConfig[]>([]);

  const extraList = ref<NaviExtra[]>([]);

  type NaviExtra = {
    title: string;
    description: string;
    icon: string;
  };

  const handlePopupVisible = (menuItem: MenuConfig) => {
    if (menuItem.children && menuItem.children.length > 0) {
      console.log(menuItem);
      currentMenu.value = menuItem;
      popupVisible.value = true;
      subMenuList.value = menuItem.children;
      extraList.value = menuItem.meta?.extraList as NaviExtra[];
    }
  };

  const closePopupVisible = () => {
    popupVisible.value = false;
    subMenuList.value = [];
    extraList.value = [];
  };

  const currentMenu = ref<MenuConfig>();
</script>
<template>
  <div class="w-full navi-menu flex overflow-x-hidden text-ellipsis whitespace-nowrap bg-header">
    <div class="mx-sm text-md z-[11]" v-for="item in menuList" :key="item.path" @mouseenter="handlePopupVisible(item)">
      <router-link
        :class="[matched.find((it) => item.path === it.path) ? 'menu-item-active' : undefined, 'text-text']"
        :to="item.meta?.redirect ?? item.path"
      >
        <component :is="item.meta?.icon" />
        <span class="ml-xxs">{{ item.title }}</span>
      </router-link>
    </div>
    <Teleport to="body">
      <div
        @mouseleave="closePopupVisible"
        :class="`bg-white rounded z-10 left-0 right-0 navi-menu-popup shadow-md min-h-[300px] absolute ${
          popupVisible ? 'visible' : ''
        }`"
      >
        <div class="navi-menu-popup-main flex h-full">
          <div class="navi-menu-popup-left flex-0 w-[300px] px-[64px] py-xl">
            <div class="text-lg font-semibold">{{ currentMenu?.title }}</div>
            <div class="font-light mt-sm">{{ currentMenu?.meta?.description }}</div>
          </div>
          <div class="navi-menu-popup-content flex flex-1 bg-[#f1f2f5] p-xl columns-auto max-h-full">
            <div
              class="mx-md flex-1 flex-col flex break-inside-avoid"
              v-for="menuItem in subMenuList"
              :key="menuItem.path"
            >
              <div class="font-semibold text-[15px]">{{ menuItem.title }}</div>
              <a
                class="not-[last-child]:mt-sm text-text text-[13px]"
                v-for="item in (menuItem.meta?.linkList as any)"
                target="_blank"
                :key="item.path"
                :href="item.href"
              >
                {{ item.title }}
                <a-tag color="red" v-if="item.badge">{{ item.badge }}</a-tag>
              </a>
            </div>
            <div v-if="extraList?.length > 0">
              <div
                v-for="item in extraList"
                class="white-box min-w-[300px] px-xl py-lg relative not-[:first-child]:mt-xl"
              >
                <div class="text-lg font-bold mb-xs">{{ item.title }}</div>
                <div>{{ item.description }}</div>
                <IconFont class="text-[40px] absolute right-[32px] top-[-12px]" :name="item.icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="less" scoped>
  .navi-menu {
    .menu-item-active {
      color: var(--color-primary);
    }
  }
  .navi-menu-popup {
    transition: all 0.25s ease-in-out;
    top: var(--height-header);
    position: absolute;
    transform: translateY(-120%);
    &.visible {
      transform: translateY(0);
    }
  }
</style>
<style lang="less">
  .stepin-layout .stepin-layout-header .stepin-header {
    position: relative;
    z-index: 9;
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.05);
  }
  .white-box {
    border: 2px solid #fff;
    background-image: linear-gradient(0deg, #ffffff 0%, #f3f5f8 100%);
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1), -8px -8px 20px 0 #ffffff;
    border-radius: 4px;
  }
</style>
