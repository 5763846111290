<script lang="ts" setup>
  import { PropType, computed } from 'vue';
  import MuseMenuItem from './MuseMenuItem.vue';
  import { useRoute } from 'vue-router';
  const props = defineProps({
    menuList: Array as PropType<any>,
    collapsed: Boolean,
    theme: String,
  });

  const route = useRoute();

  const openKeys = computed(() => route.matched.map((item) => item.path));
</script>
<template>
  <div
    :style="`--pr: ${collapsed ? 'var(--padding-xxs)' : 'var(--padding-md)'}`"
    :class="`beautiful-menu overflow-y-visible`"
  >
    <a-menu
      inlineIndent="null"
      mode="inline"
      :inlineCollapsed="collapsed"
      :selected-keys="[route.fullPath]"
      :open-keys="openKeys"
    >
      <MuseMenuItem
        v-for="item in menuList"
        :title="item.title"
        :path="item.path"
        :children="item.children"
        :icon="item.meta.icon"
        :meta="item.meta"
      />
    </a-menu>
  </div>
</template>
<style lang="less">
  #stepin-app {
    .stepin-layout-side {
      overflow-y: auto;
    }
    .stepin-view .stepin-layout-header {
      box-shadow: none;
    }
  }
</style>
<style lang="less" scoped>
  .beautiful-menu {
    --shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
    --pl: var(--padding-xxs);
    --p-icon: var(--padding-xs);
    padding-left: var(--pl);
    padding-right: var(--pr);
    user-select: none;
    :deep(.ant-menu) {
      border-right: none;
      .ant-menu-item {
        padding-left: 16px;
      }
      .ant-menu-submenu,
      .ant-menu-item {
        margin: 8px 0px;
        .anticon {
          // margin-left: calc(0px - var(--pl) - var(--p-icon));
          padding: var(--p-icon);
          border-radius: var(--radius-sm);
          box-shadow: 0 0 8px 1px var(--color-shadow);
          color: var(--color-text-3);
        }
        &::after {
          border-right: none;
        }
        .ant-menu-sub {
          background: transparent;
          .ant-menu-title-content {
            // margin-left: calc(var(--p-icon) + var(--font-size) - var(--pl) + 10px - 24px);
            margin-left: calc(2 * var(--p-icon) + var(--font-size) + 10px);
          }
          .ant-menu-item {
            &::before {
              content: '';
              height: 6px;
              width: 6px;
              position: absolute;
              left: 32px;
              top: 50%;
              border-radius: 8px;
              background-color: var(--color-text-3);
              transform: translateX(-50%) translateY(-50%);
            }

            &.ant-menu-item-selected {
              box-shadow: none;
              color: var(--color-title);
              background: transparent;
              &::before {
                transform: translateX(-50%) translateY(-50%) scale(1.35);
                background-color: var(--color-primary);
              }
            }
          }
        }
      }
      .ant-menu-item-selected,
      .ant-menu-submenu:has(.ant-menu-item-selected) .ant-menu-submenu-title,
      .ant-menu-submenu-title:hover {
        // box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
        // border-radius: var(--radius-md);
        background: transparent;

        .anticon {
          box-shadow: none;
          background: var(--color-primary);
          color: var(--color-text-inverse);
        }
      }
      &:not(.ant-menu-inline-collapsed) {
        .ant-menu-item-selected,
        .ant-menu-submenu:has(.ant-menu-item-selected) .ant-menu-submenu-title,
        .ant-menu-submenu-title:hover {
          box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
          border-radius: var(--radius-md);
          background: var(--color-bg-menu);
        }
      }
      .ant-menu-item,
      .ant-menu-submenu-title {
        line-height: 52px;
        height: 52px;
      }
      &.ant-menu-inline-collapsed {
        width: calc(var(--width-side-collapsed) - 2 * var(--padding-xxs));
        .ant-menu-item {
          // padding-left: 24px;
          .ant-menu-item-icon {
            line-height: 1;
          }
        }
        .ant-menu-submenu {
          padding-bottom: 0;
          .ant-menu-item-icon {
            line-height: 1;
          }
          .ant-menu-submenu-title {
            padding-left: 16px;
          }
        }
      }
    }
  }
</style>
